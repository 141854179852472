.connection .container {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 900px) {
  .connection .container {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.connection .field-item {
  width: 90%;
  margin: 8px auto;
}

@media only screen and (max-width: 900px) {
  .connection .field-item {
    width: 98%;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}

.connection .text-field {
  width: 100%;
}

.connection .terms {
  font-size: .9rem;
}

.connection .password {
  color: #d8d8d8;
  font-size: .9rem;
  font-weight: 300;
}

.connection .paper {
  padding: 1rem;
}

.connection .title {
  text-align: center;
  margin: 1.2rem;
  font-size: 2.4rem;
}

.connection .card-content {
  width: 80%;
  margin: auto;
}

.connection .card-content .card-title {
  margin-bottom: 3rem;
  font-size: 2rem;
}

.connection a {
  color: #a7a7a7;
  text-decoration: underline;
}

.connection h6 {
  font-size: .9rem;
}

.connection .connection-externals {
  text-align: center;
}

.connection .connection-externals .connection-google {
  margin: 10px 0 20px;
}

.connection .connection-externals .connection-google .connection-google-button {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  min-height: 34px;
  text-shadow: none;
  white-space: nowrap;
  background: #4184f3 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAaVJREFUOBG9lM8rRFEUx%2BchkQVp8iv5A7CwtsGCkiQWLJT8ASxkYSGxsrHyY2tHWZCysZYNoVjPRk2omaL8nIVcn%2FOcV3feu%2FPmrZz6OOee8%2F3ed8fMu6nUf4YxxoNp2IUr%2BIBv%2BIFXOIL2RGdCWAfHcANtYiIPg2wkG44m2igQYVgBib6gJ5n1kt815p3cas9ia8QPaqy1hfRq4FlnE%2FZM6opwQ9aIPVKzzuo1%2B8nzvAJFRnsd9kxq54aYDLN7ERAzf6nob1pXl0XduAWnXNWP9UL2vxTRUw9o%2FzDOH5lhqoITNT%2BS12Ad5AGSqyOmJA2MvTAHOyCxl8RXVsNGk%2F52xnyRZ8saSgkwd4O8LfMgb0kQmxSVpXyRPuImOFV3nnwG1%2FCpPUnLEaOrgTANGcjBuK1h3QDbIFGARnvurBFtiZoYdApoMjv3Fcb02xrnDxvBmIoubHGozuo6H%2BpHlzw5q09fjE790%2FUwfwO5ieQ1jQ9ECxDEPsUIdMEQbIDci0%2FQGb%2BTNUU8BXcQDvnpHIDzYi17XIxy58lJWkD%2BX7dcHjmyM34BtJe3X9SmBnsAAAAASUVORK5CYII%3D") 10px no-repeat;
  border-color: #3b5998;
  border-radius: 3px;
  width: 280px;
  padding: 9px 10px 9px 40px;
  font-size: 1rem;
  line-height: 1.4em;
  text-decoration: none;
  transition: border-color .1s linear, background .1s linear, color .1s linear;
  display: inline-block;
  position: relative;
}

.connection .connection-externals .connection-google .connection-google-button:hover {
  color: #fff;
  background-color: #346ac2;
  border-color: #346ac2;
  outline: 0;
  text-decoration: none;
}

.connection .connection-checkbox > .input-field {
  margin-top: 0;
}

.connection .btn {
  letter-spacing: 1.5px;
  background-color: #c82b08;
  font-weight: 600;
}

.connection .container-action {
  margin-top: 0;
  margin-bottom: 2rem;
}

.connection .container-action .MuiGrid-item {
  padding: 6px !important;
}

@media only screen and (max-width: 900px) {
  .connection .MuiGrid-container {
    max-width: 98%;
  }
}

.password.container {
  margin: 2rem auto;
}

.password .title {
  text-align: center;
  margin: 2.4rem;
  font-size: 2.4rem;
}

.password .field-item {
  width: 90%;
  margin: 4px auto;
}

@media only screen and (max-width: 900px) {
  .password .field-item {
    width: 95%;
  }
}

.password .text-field {
  width: 100%;
  margin: 12px;
}

.password .link {
  color: #d8d8d8;
  font-size: .9rem;
  font-weight: 300;
}

.connection-admin {
  max-width: 660px;
  margin-top: 45px !important;
}

#error_explanation h2 {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.3rem;
}

.static-content {
  text-align: justify;
  margin: 3rem auto 4rem;
  position: relative;
  overflow: visible;
}

@media only screen and (max-width: 900px) {
  .static-content {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.static-content h2 {
  font-size: 1.4rem;
}

.static-content p, .static-content ul {
  margin-top: .4rem;
  margin-bottom: .4rem;
  font-size: .9rem;
  line-height: 1.7rem;
}

.static-title {
  color: #7a1802;
  text-align: center;
  text-transform: uppercase;
  margin: 1.5rem auto;
  font-size: 1.8rem;
  font-weight: 600;
}

.static-subtitle {
  color: #7a1802;
  margin: 2.5rem 0 .5rem;
  font-size: 1.6rem;
}

.card {
  background-color: #fff;
  border-radius: 2px;
  margin: .5rem 0 1rem;
  position: relative;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px rgba(0, 0, 0, .2);
}

.card .card-title {
  color: #7a1802;
  margin-bottom: 25px;
  font-size: 1.6rem;
}

.card .card-content {
  border-radius: 0 0 2px 2px;
  padding: 24px;
}

.card .card-action {
  background-color: inherit;
  border-top: 1px solid rgba(160, 160, 160, .2);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.card .card-btn {
  color: #fff;
  box-sizing: border-box;
  letter-spacing: .02857em;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #c82b08;
  border: 0;
  border-radius: 4px;
  min-width: 64px;
  padding: 8px 16px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75;
  text-decoration: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}

.contact-email {
  color: rgba(0, 0, 0, .87);
  font-size: .85rem;
  text-decoration: underline;
}
